import React from 'react';
import { scrollToSection } from './Navbar';

const HowItWorks = () => {
  return (
    <section className="how-it-works" id="how-it-works">
      <div className="gradient-bg">
        <h2>How it works</h2>
        <div className="steps-container">
          <div className="step">
            <h3>Step 1<br/>Reach Out</h3>
            <p>If you're a content creator or influencer with an engaged community, contact us to discuss your plushie ideas. If you are in Miami, let's just grab a coffee. We see most value can be created for creators with engaged community of 100K+ followers</p>
          </div>
          
          <div className="step">
            <h3>Step 2<br/>Design and Launch</h3>
            <p>We'll design the plush toy with your input, finalize the details, and set the dates for your crowdfunding campaign</p>
          </div>
          
          <div className="step">
            <h3>Step 3<br/>Crowdfunding</h3>
            <p>Your campaign will run for 30 days, during which you'll promote it through your social media channels</p>
          </div>
          
          <div className="step">
            <h3>Step 4<br/>Manufacturing and Shipping</h3>
            <p>Once the campaign is successfully funded, we'll handle manufacturing and shipping worldwide. We'll also manage all customer support, and transfer the net funds to you after deducting production costs and fees</p>
          </div>
        </div>
        <div className="mt-12 mr-36 text-end">
        <button
          className="px-8 py-4 bg-[#410AF3] text-white font-bold rounded-full text-lg transform transition-all duration-300 hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          aria-label="Get started with our platform"
          onClick={()=>scrollToSection('#contact')}
        >
          Let's do it!
        </button>
      </div>
      </div>
    </section>
  );
};

export default HowItWorks; 