import React from "react";
import { FaDollarSign, FaBolt, FaHeart } from "react-icons/fa";
import { scrollToSection } from "./Navbar";

const Benefits = () => {
  return (
    <section className="benefits" id="benefits">
      <h2>Benefits for Content Creators</h2>
      <div className="benefits-grid">
        <div
          className="benefit-card"
          style={{
            position: "relative",
            paddingTop: "60px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-30px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80px",
              height: "80px",
              backgroundColor: "#FAFAFA",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: "10",
            }}
          >
            <FaDollarSign className="benefit-icon text-green-500 text-4xl" />
          </div>
          <h3>New Revenue Stream</h3>
          <hr />
          <p className="leading-relaxed">
            • One of the best revenue-sharing models on the market
            <br /><br/>
            • You don't spend a dime
          </p>
        </div>

        <div
          className="benefit-card"
          style={{
            position: "relative",
            paddingTop: "60px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-30px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80px",
              height: "80px",
              backgroundColor: "#FAFAFA",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: "10",
            }}
          >
            <FaBolt className="benefit-icon text-yellow-500 text-4xl" />
          </div>
          <h3>Fast and Effortless</h3>
          <hr />
          <p className="leading-relaxed">
            • From design to delivery, we manage production, shipping, and customer support
            <br /><br/>
            • All you need to do is approve – we'll take care of the rest
            <br /><br/>
            • Launch campaign in days
          </p>
        </div>

        <div
          className="benefit-card"
          style={{
            position: "relative",
            paddingTop: "60px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-30px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80px",
              height: "80px",
              backgroundColor: "#FAFAFA",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: "10",
            }}
          >
            <FaHeart className="benefit-icon text-red-500 text-4xl" />
          </div>
          <h3>Happy Fans</h3>
          <hr />
          <p className="leading-relaxed">
            • High-quality plushies that bring your content to life
            <br /><br/>
            • Each plushie is a keepsake, creating lasting memories and stronger connections with your community
          </p>
        </div>
      </div>
      <div className="mt-12 text-center">
        <button
          className="px-8 py-4 bg-[#410AF3] text-white font-bold rounded-full text-lg transform transition-all duration-300 hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          aria-label="Get started with our platform"
          onClick={() => scrollToSection('#contact')}
        >
          Let's do it!
        </button>
      </div>
    </section>
  );
};

export default Benefits;
