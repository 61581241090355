import { useState } from "react";
import { FaSpinner } from "react-icons/fa";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    socialMedia: "",
    message: ""
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === "email" && value) {
      setErrors(prev => ({
        ...prev,
        email: validateEmail(value) ? "" : "Please enter a valid email address"
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }
    if (!formData.socialMedia.trim()) {
      newErrors.socialMedia = "Social media profile is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      // Simulating API call
      const response = await fetch('https://api.fandomtribe.com/api/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"data":formData}) 
      });

      setSubmitted(true);
      setFormData({
        name: "",
        email: "",
        phone: "",
        socialMedia: "",
        message: ""
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen  p-4 md:p-8" id="contact">
      <hr className="mb-12 h-0.5 bg-gray-800"></hr>
      <h2 className="text-5xl font-bold text-center mb-8 text-gray-800">Contact us to discuss campaign launch</h2>
      <div className="max-w-2xl mx-auto bg-white p-6 md:p-8 transform transition-all duration-300">
      
        {submitted ? (
          <div className="text-center py-8">
            <h2 className="text-2xl font-semibold text-green-600 mb-4">Thank you for your message!</h2>
            <p className="text-gray-600 mb-6">We will get back to you soon.</p>
            <button
              onClick={() => setSubmitted(false)}
              className="bg-[#410AF3] text-white px-6 py-2 rounded-lg hover:bg-[#410AF3] transition-colors duration-300"
            >
              Send Another Message
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} noValidate>
            
            <div className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-xl font-medium text-gray-700 mb-1">
                  Full Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#410AF3] outline-none transition-all duration-300 ${errors.name ? "border-red-500" : "border-gray-300"}`}
                  aria-describedby="name-error"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-600" id="name-error" role="alert">
                    {errors.name}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="email" className="block text-xl font-medium text-gray-700 mb-1">
                  Email Address *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#410AF3] outline-none transition-all duration-300 ${errors.email ? "border-red-500" : "border-gray-300"}`}
                  aria-describedby="email-error"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600" id="email-error" role="alert">
                    {errors.email}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="phone" className="block text-xl font-medium text-gray-700 mb-1">
                  Phone/WhatsApp (Optional)
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#410AF3] outline-none transition-all duration-300"
                />
              </div>

              <div>
                <label htmlFor="socialMedia" className="block text-xl font-medium text-gray-700 mb-1">
                  Social Media Profile (with most followers) *
                </label>
                <input
                  type="text"
                  id="socialMedia"
                  name="socialMedia"
                  value={formData.socialMedia}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#410AF3] outline-none transition-all duration-300 ${errors.socialMedia ? "border-red-500" : "border-gray-300"}`}
                  aria-describedby="social-error"
                />
                {errors.socialMedia && (
                  <p className="mt-1 text-sm text-red-600" id="social-error" role="alert">
                    {errors.socialMedia}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="message" className="block text-xl font-medium text-gray-700 mb-1">
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#410AF3] outline-none transition-all duration-300 ${errors.message ? "border-red-500" : "border-gray-300"}`}
                  aria-describedby="message-error"
                ></textarea>
                {errors.message && (
                  <p className="mt-1 text-sm text-red-600" id="message-error" role="alert">
                    {errors.message}
                  </p>
                )}
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-[#410AF3] text-white py-3 rounded-lg font-semibold hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition-all duration-300 disabled:opacity-70 flex items-center justify-center space-x-2"
              >
                {loading ? (
                  <>
                    <FaSpinner className="animate-spin" />
                    <span>Sending...</span>
                  </>
                ) : (
                  <span>SEND MESSAGE</span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;