import { FaPhone } from "react-icons/fa";
import ProductPage from "../product/product";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import { scrollToSection } from "../../components/Navbar";

export const Demo = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch product data when the component mounts
  useEffect(() => {
    const fetchProduct = async () => {
      if (!id) return;
      try {
        const response = await fetch(
          `https://api.fandomtribe.com/api/products?populate[images][fields][0]=url&populate[images][fields][1]=name&filters[slug][$eq]=${id}`
        );
        const data = await response.json();
        setProduct(data.data[0]?.attributes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner">Loading...</div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Error loading product. Please try again later.</p>
      </div>
    );
  }

  return (
    <>
      {/* Use Helmet to dynamically set the title */}
      <Helmet>
        <title>{product.title ? product.title : "Loading..."}</title>
      </Helmet>

      <section className="mt-24" id="home">
        <h1
          className="text-2xl lg:text-3xl font-bold text-gray-800 mb-6 text-center"
          aria-label="Commercial Proposal - Non-Binding"
        >
          <span className="font-bold">Commercial Proposal</span>{" "}
          <span className="font-normal">(Non-Binding)</span>
        </h1>

        <div className="max-w-6xl mx-auto px-4 flex flex-col gap-8 items-start">
          <h1>Hey {product.name},</h1>
          <ReactMarkdown>{product.email}</ReactMarkdown>
          <button
            className="mb-6 bg-[#410AF3] text-white px-6 py-3 rounded-lg flex items-center gap-2 transform transition-all duration-300 hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ml-auto"
            onClick={() => scrollToSection('#contact')}
            aria-label="Schedule a call to discuss the proposal"
          >
            <FaPhone className="text-lg" />
            <span>Schedule Call</span>
          </button>
        </div>

        <h1 className="max-w-6xl mx-auto text-4xl font-medium" id="demo">
          Below is a demo only, not a real crowdfunding campaign
        </h1>
        <div className="relative m-4 p-[6px] rounded-lg bg-gradient-custom">
          <div className="bg-white rounded-lg p-4">
            <ProductPage product={product} />
          </div>
        </div>
        <h1 className="text-center mx-auto text-4xl font-medium" id="demo">
          End of Demo
        </h1>
        <style jsx>{`
          .bg-gradient-custom {
            background: linear-gradient(
              67deg,
              #812be2 9%,
              #802ae4 17%,
              #ab40bd 32%,
              #c64ea6 60%,
              #d55f8c 81%,
              #de8b6f 100%
            );
          }
        `}</style>
      </section>
    </>
  );
};
