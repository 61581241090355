import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { scrollToSection } from "./Navbar";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: "Can I work with FandomTribe?",
      answer: "Yes, FandomTribe works with a variety of creators, including artists, influencers, and content creators from different backgrounds. If you have a unique idea for a plush toy, FandomTribe is open to partnering with you."
    },
    {
      question: "How do you run crowdfunding campaigns?",
      answer: "FandomTribe sets up crowdfunding campaigns on its platform, where creators can promote their plush toy designs to their audience. Fans pledge support by pre-ordering the plush toy, and if the campaign reaches its funding goal, production begins."
    },
    {
      question: "How much will I earn from my campaign?",
      answer: " Earnings depend on the success of the campaign. Once the campaign ends, creators receive a share of the profits from the total sales. FandomTribe offers competitive profit-sharing with creators."
    },
    {
      question: "What happens if my campaign doesn’t reach its funding goal?",
      answer: "If the campaign doesn’t reach its funding goal, backers are not charged, and the plush toy does not go into production. There is no cost for creators in this case, so there’s no risk to trying a campaign."
    },
    {
      question: "Can international creators work with FandomTribe?",
      answer: "Yes, FandomTribe welcomes creators from around the world. International creators can partner with FandomTribe to launch their plush toy campaigns."
    },
    {
      question: "Is there any cost to start a campaign?",
      answer: "No, there is no upfront cost for creators to start a campaign with FandomTribe. All costs are covered by the funds raised during the crowdfunding campaign."
    },
    {
      question: "How long does it take to produce and ship plush toys?",
      answer: "Once a campaign is successful and ends, production and shipping typically take around 2-3 months. FandomTribe handles the manufacturing and logistics to ensure quality and timely delivery."
    }
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-16" id="faq">
      <h2 className="text-5xl font-bold text-center mb-12 text-gray-800">Frequently Asked Questions</h2>
      
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg overflow-hidden"
          >
            <button
              className="w-full px-6 py-4 flex justify-between items-center bg-white hover:bg-gray-50 transition-colors duration-200"
              onClick={() => toggleAnswer(index)}
              aria-expanded={activeIndex === index}
              aria-controls={`faq-answer-${index}`}
            >
              <span className="text-xl font-semibold text-left text-gray-900">{faq.question}</span>
              <span className="ml-4 flex-shrink-0">
                {activeIndex === index ? (
                  <FiMinus className="h-5 w-5 text-blue-600" />
                ) : (
                  <FiPlus className="h-5 w-5 text-blue-600" />
                )}
              </span>
            </button>

            <div
              id={`faq-answer-${index}`}
              className={`transition-all duration-300 ease-in-out ${
                activeIndex === index
                  ? "max-h-96 opacity-100"
                  : "max-h-0 opacity-0"
              } overflow-hidden`}
            >
              <div className="p-6 bg-white border-t border-gray-200">
                <p className="text-gray-700">{faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-12 text-center">
        <button
          className="px-8 py-4 bg-[#410AF3] text-white font-bold rounded-full text-lg transform transition-all duration-300 hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          aria-label="Get started with our platform"
          onClick={()=>scrollToSection('#contact')}
        >
          Let's do it!
        </button>
      </div>
    </div>
  );
};

export default FAQ;