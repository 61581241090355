import { Children, useEffect } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import "./app.scss"

import LandingPage from "./pages/LandingPage";
import { Home } from "./pages/home/home";
import { Demo } from "./pages/demo/demo";


const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage/>,
    children:[
      {
        path:"/",
        element: <Home/>
      },
      {
        path:"/demo/:id",
        element: <Demo/>
      }
    ]
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}


export default App;

