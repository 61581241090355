import React from 'react';
import Navbar from '../components/Navbar';
import HowItWorks from '../components/HowItWorks';
import FAQ from '../components/FAQ';
import Contact from '../components/Contact';
import { Outlet } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Navbar />
       <Outlet/>
      <HowItWorks />
      <FAQ />
      <Contact />
    </div>
  );
};

export default LandingPage; 